<template>
  <div class="privacy">
    <h1
      style="
        margin-top: 17pt;
        margin-bottom: 16.5pt;
        text-align: center;
        page-break-inside: avoid;
        page-break-after: avoid;
        line-height: 240%;
        font-size: 22pt;
      "
    >
      <span style="font-family: 宋体">树枝记账用户协议</span>
    </h1>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体"
        >欢迎您使用树枝记账（详见定义条款）为您提供的服务。</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体"
        >为了保障您的权益，请在使用树枝记账服务（详见定义条款）前，详细阅读并理解本协议（详见定义条款）的所有内容，特别是免除或者限制责任的条款、争议解决和法律适用条款以及开通或使用某项服务的单独协议，及选择接受或不接受的部分。一旦您采取以合理的理解表明您希望与树枝记账平台运营者（详见定义条款）签订本协议的行为（例如，点击按钮上书写“同意《树枝记账使用协议》”或类似文字，页面上同时列明了本协议的内容或者可以有效展示本协议内容的链接；或您下载、安装、获取</span
      ><span>/</span
      ><span style="font-family: 宋体"
        >激活、登录含服务内容的树枝记账并使用服务），即视为您已充分阅读、理解并接受本协议的全部内容，并与树枝记账达成协议。如您对本协议有任何疑问的，应向树枝记账客服咨询。如果您不同意本协议的约定，可能会影响您使用树枝记账为您提供的部分服务，建议您立即停止使用树枝记账服务。</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体"
        >您与树枝记账达成协议后，您承诺接受并遵守本协议的约定，并不得以未阅读本协议的内容或者未获得平台对您问询的解答等理由，主张本协议无效，或要求撤销本协议。在本协议履行过程中，树枝记账可以依其单独判断暂时停止提供、限制或改变平台服务。一旦本协议的内容发生变动，树枝记账将通过平台公布最新的服务协议，不再向您作个别通知。如果您不同意树枝记账对本服务协议所做的修改，您有权停止使用树枝记账服务。如果您继续使用树枝记账服务，则视为您接受树枝记账对本协议所做的修改，并应遵照修改后的协议执行。您知晓，有关服务产生的相关争议受您和其他主体签订服务协议约束并依据相应服务协议来处理。</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体"
        >本协议内容包括协议正文、法律声明、树枝记账规则及所有树枝记账已经发布或将来可能发布的各类规则、公告或通知（以下简称“本协议”）。所有规则为本协议不可分割的组成部分，与协议正文具有同等
        法律效力。</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">定义：</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span>1.</span><span style="font-family: 宋体">树枝记账：包括但不限于网址为</span
      ><span>www.nilwy.com</span
      ><span style="font-family: 宋体">或树枝记账指定的其他网址及其移动客户端以及以</span
      ><span>APP</span
      ><span style="font-family: 宋体"
        >形式提供的操作环境。用户注册或使用前述网站或客户端时，则树枝记账仅指相应的网站或客户端。本协议同样适用未来或未列明的采用树枝记账账号体系的网址或移动客户端服务。</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span>2.</span
      ><span style="font-family: 宋体"
        >树枝记账账号体系：指您按照树枝记账提供的方式取得的，供您使用树枝记账服务的编号，树枝记账账号体系可用于登陆以上各平台。树枝记账账号登录名可以是您的手机号码，此外树枝记账账号体系也支持您选择通过第三方平台授权登陆。</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span>3.</span
      ><span style="font-family: 宋体"
        >树枝记账运营者：包括但不限于厦门逆为网络科技游戏公司。本协议项下，树枝记账经营者可能根据树枝记账的业务调整而发生变更，变更后的树枝记账经营者与您共同履行本协议并向您提供服务，树枝记账经营者的变更不会影响您本协议项下的权益。树枝记账经营者还有可能因为提供新的树枝记账服务而新增，如您使用新增的树枝记账服务的，视为您同意新增的树枝记账经营者与您共同履行本协议。发生争议时，您可根据您具体使用的服务及对您权益产生影响的具体行为对象确定与您履约的主体及争议相对方。</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span>4</span
      ><span style="font-family: 宋体"
        >．树枝记账服务：树枝记账基于互联网，以包含树枝记账网站、移动客户端等在内的各种形态（包括未来技术发展出现的新的服务形态）向您提供的各项服务。</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span>5</span
      ><span style="font-family: 宋体"
        >．树枝记账注册协议：本协议内容包括协议正文、法律声明、平台规则及所有平台已经发布或将来可能发布的各类规则、公告或通知。</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span>6</span
      ><span style="font-family: 宋体"
        >．树枝记账规则：包括在树枝记账已经发布及后续发布的全部规则、解读、公告等内容以及树枝记账在论坛、帮助中心内发布的各类规则、实施细则、流程说明等。</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">一、账户注册及使用</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span>1</span
      ><span style="font-family: 宋体"
        >、您确认，在您开始注册程序使用平台服务前，您应当具备中华人民共和国法律规定的与您行为相适应的民事行为能力并符合本协议第二条树枝记账用户中要求的其他条件。若您不具备前述与您行为相适应的民事行为能力，则您及您的监护人应依照法律规定承担因此而导致的一切后果。</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span>2</span
      ><span style="font-family: 宋体"
        >、您设置的用户名不得违反国家法律法规及树枝记账规则关于用户名的管理规定，否则树枝记账可回收您的用户名。用户名的回收不影响您以邮箱、手机号码登录树枝记账并使用树枝记账服务。</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span>3</span
      ><span style="font-family: 宋体"
        >、您的注册账户为您自行设置并由您保管，除本协议或法律法规另有约定的情形外，树枝记账任何时候均不会主动要求您提供您的账户密码。您注册了树枝记账账户则视为树枝记账用户，建议您务必保管好您的树枝记账账户，并确保您在每个上网时段结束时退出登录并以正确步骤离开树枝记账。注册账户因您主动泄露或因您遭受他人攻击、诈骗等行为导致的损失及后果，树枝记账并不承担责任，您应通过司法、行政等救济途径向侵权行为人追偿。</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span>4</span
      ><span style="font-family: 宋体"
        >、您不得将在树枝记账注册获得的账户借给他人使用，否则您应承担由此产生的全部责任，并与实际使用人承担连带责任。</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span>5</span
      ><span style="font-family: 宋体"
        >、您同意，在应国家司法机关、行政机关的要求下、紧急情况下或其他符合国家法律法规明确规定的情形下，树枝记账有权在未告知您的情形下使用您的注册信息、用户名、密码等信息，登录进入您的注册账户，进行证据保全，包括但不限于公证、见证等。</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">二、树枝记账用户</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span>1</span
      ><span style="font-family: 宋体"
        >、树枝记账用户应遵循诚实守信、责任自负、风险自担的原则承担使用树枝记账服务所产生的风险，在使用服务应当同时具备以下条件：</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">（</span><span>1</span
      ><span style="font-family: 宋体"
        >）具有完全民事权利和民事行为能力，能够独立承担民事责任的自然人、法人或其他组织；</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">（</span><span>2</span
      ><span style="font-family: 宋体"
        >）按照相关法律法规及树枝记账要求进行实名注册；</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">（</span><span>3</span
      ><span style="font-family: 宋体"
        >）相应法律法规及树枝记账规定、及树枝记账有关协议约定的其他条件。</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span>2</span
      ><span style="font-family: 宋体"
        >、树枝记账有权要求树枝记账用户提供真实、准确、完整、合法有效的个人信息，用户同意树枝记账可自行或委托第三方信息服务树枝记账（包括但不限于征信机构、中国支付清算协会互联网金融风险信息共享系统、中国人民银行金融信用信息基础数据库、公安部公民身份信息数据库、其他互联网数据树枝记账等）查询、采集、使用、识别、处理与树枝记账用户个人信息、个人征信信息及其他反应树枝记账用户信用状况的信息，包括但不限于个人基本信息、借贷交易信息、银行卡交易信息、手机账单信息、通讯记录信息、短信信息、电商交易信息、公积金、公用事业信息、央行征信报告、能够联系到个人的实体地址、电子邮箱地址、微信号、反欺诈信息等。</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span>3</span
      ><span style="font-family: 宋体"
        >、树枝记账用户在使用服务时将生成其个人的树枝记账账户（以下简称“树枝记账账户”），树枝记账账户将记载树枝记账用户在树枝记账的所有活动，是树枝记账用户登陆树枝记账并使用服务时的唯一账户。该用户账号和密码由树枝记账用户负责保管；用户应当对以其用户账号进行的所有活动和事件负法律责任。</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span>4</span
      ><span style="font-family: 宋体"
        >、每个树枝记账用户有义务维持并更新树枝记账用户的资料，确保其为真实、最新、有效及完整；承诺按约定如实报告影响或可能影响交易对方权益、树枝记账提供服务的重大信息。若树枝记账用户提供任何错误、虚假、过时或不完整的资料，或者树枝记账依其独立判断怀疑资料为错误、虚假、过时或不完整，树枝记账有权暂停或终止或以其他方式限制树枝记账用户的树枝记账账户，并拒绝树枝记账用户使用树枝记账服务的部分或全部功能。在此情况下，树枝记账不承担任何责任，并且树枝记账用户或其监护人同意负担用户及树枝记账因此所产生的直接或间接的任何支出或损失。
        如因树枝记账用户未及时更新基本资料，导致树枝记账服务无法提供或提供时发生任何错误，树枝记账用户不得将此作为取消交易或拒绝付款的理由，树枝记账亦不承担任何责任，所有后果应由树枝记账用户承担。</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span>5</span
      ><span style="font-family: 宋体"
        >、树枝记账方有权对树枝记账用户采取措施防范欺诈行为，发现欺诈行为或其他损害其他树枝记账用户利益的情形，树枝记账有权立即终止该树枝记账用户继续使用树枝记账活动并要求用户承担树枝记账因此而导致的一切支出或损失。</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span>6</span
      ><span style="font-family: 宋体"
        >、树枝记账服务的部分内容需要树枝记账用户根据树枝记账要求完成身份认证及银行卡认证，未进行身份认证及</span
      ><span>/</span
      ><span style="font-family: 宋体"
        >或银行卡认证的树枝记账用户将无法使用该部分及相关的树枝记账服务。因未能完成认证而无法享受树枝记账服务造成的损失，树枝记账不承担任何责任。</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span>7</span
      ><span style="font-family: 宋体"
        >、树枝记账对于树枝记账用户的通知及任何其他的协议、告示或其他关于树枝记账用户使用树枝记账账户及服务的通知，树枝记账用户同意树枝记账可通过树枝记账平台公告、电子邮件、手机短信、无线通讯装置等电子方式或常规的信件传递、电话通讯等方式进行，该等通知于发送之日视为已送达收件人。因信息传输等原因导致树枝记账用户未在前述通知发出当日收到该等通知的，树枝记账不承担责任。</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">三、树枝记账服务内容</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span>1</span
      ><span style="font-family: 宋体"
        >、树枝记账服务由树枝记账运营者提供并受各自相应服务协议约束，树枝记账运营者可以根据业务发展需要，调整提供服务种类，树枝记账将为用户提供以下服务：</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">（</span><span>1</span
      ><span style="font-family: 宋体"
        >）第三方信息展示服务，第三方信息展示服务的内容不代表树枝记账运营者对内容进行过任何实质性审查或者进行任何明示或暗示的推荐，用户根据自主判断点击第三方展示信息并决定是否进行后续操作。树枝记账用户点击了第三方展示信息后，会自动跳转到第三方自有网站或者移动客户端进行进一步信息确认或交易。</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">（</span><span>2</span
      ><span style="font-family: 宋体"
        >）根据用户或用户授权的第三方指令，为用户提供信息发布、交易管理、交易撮合等交易辅助服务。</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">（</span><span>3</span
      ><span style="font-family: 宋体"
        >）提供手动记账，通过明细、报表统计、账单等一系列功能，帮助用户方便快捷地管理自己的财务状况。</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span>2</span
      ><span style="font-family: 宋体"
        >、用户知晓、理解并同意：树枝记账将为树枝记账用户提供通过本树枝记账点击第三方平台交易后的交易信息采集服务。树枝记账通过树枝记账用户以及第三方平台的授权，从第三方网站采集树枝记账用户的相关交易信息，由于数据来自第三方网站，因此树枝记账不对信息的准确、完整、合法性作出保证，也不承担相关责任，树枝记账用户应依其独立判断做出决策。若由于技术或者无法获得第三方平台授权等原因，造成数据无法采集的，树枝记账不承担任何责任，树枝记账用户应当自行到相关平台进行交易信息查询。</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span>3</span
      ><span style="font-family: 宋体"
        >、树枝记账仅对发布的信息承担表面审查责任，不对信息的准确、完整、合法性作出保证，也不构成对任何第三方交易平台的推荐或进行实质性审查，树枝记账用户应依其独立判断做出决策，树枝记账不承担任何责任。
        树枝记账用户根据树枝记账发布的信息与第三方进行交易的，产生的风险由树枝记账用户自行承担，树枝记账用户无权据此向树枝记账提出任何法律主张。在交易过程中，树枝记账用户与第三方之间发生的纠纷，由纠纷各方自行解决，树枝记账不承担任何责任。</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span>4</span
      ><span style="font-family: 宋体"
        >、树枝记账用户知晓，通过树枝记账上点击进入第三方平台获取相关信息、服务并和该第三方平台进行交易并签订相应合同，该合同仅对第三方平台以及树枝记账用户和相关主体产生法律效力，树枝记账用户应当谨慎阅读合同并独立作出判断。树枝记账在该交易中作为信息提供方不承担上述所述交易中的任何责任和损失，树枝记账用户同样知晓，将第三方平台信息放置到树枝记账中并不代表树枝记账对该第三方平台进行了任何实质性的审核或推荐该第三方平台，树枝记账仅对第三方平台的提供的信息进行形式审核。</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span>5</span
      ><span style="font-family: 宋体"
        >、树枝记账用户理解并同意，树枝记账向树枝记账用户提供信息展示服务。树枝记账对用户通过树枝记账点击进入的第三方树枝记账上进行的投资交易行为及后果不承担任何责任，树枝记账无法也没有义务保证树枝记账用户第三方平台达成的交易一定能够获得相应收益，树枝记账用户因前述原因导致的损失（包括但不限于本金、利息、手续费等损失）由树枝记账用户自行承担，树枝记账不承担责任。</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span>6</span
      ><span style="font-family: 宋体"
        >、树枝记账用户同意，树枝记账有权在提供树枝记账服务过程中以各种方式投放各种商业性广告或其他任何类型的宣传推广信息（包括商业与非商业信息，包括但不限于在树枝记账的任何页面上投放广告），树枝记账用户同意接受树枝记账通过电子邮件、通讯地址、移动电话、</span
      ><span>QQ</span
      ><span style="font-family: 宋体"
        >、微信或其他方式向树枝记账用户发送相关商业信息。如不需要树枝记账推送相关信息，树枝记账用户可联系树枝记账客服或按照树枝记账系统提示取消该服务。</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span>7</span
      ><span style="font-family: 宋体"
        >、树枝记账无需树枝记账用户同意即可向树枝记账关联实体转让与树枝记账有关的全部或部分权利和义务。未经树枝记账事先书面同意，树枝记账用户不得转让其在本协议项下的任何权利和义务。</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">四、 树枝记账用户依法言行义务</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span>1</span
      ><span style="font-family: 宋体"
        >、本协议依据国家相关法律法规规章制定，树枝记账用户同意并承诺将严格遵守以下义务：</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">（</span><span>1</span
      ><span style="font-family: 宋体"
        >）不得传输或发表：煽动抗拒、破坏宪法和法律、行政法规实施的言论，煽动颠覆国家政权，推翻社会主义制度的言论，煽动分裂国家、破坏国家统一的言论，煽动民族仇恨、民族歧视、破坏民族团结的言论；</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">（</span><span>2</span
      ><span style="font-family: 宋体"
        >）从中国大陆向境外传输资料信息时必须符合中国有关法规；</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">（</span><span>3</span
      ><span style="font-family: 宋体"
        >）不得利用树枝记账从事洗钱、窃取商业秘密、窃取个人信息等违法犯罪活动；</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">（</span><span>4</span
      ><span style="font-family: 宋体"
        >）不得干扰树枝记账的正常运转，不得侵入树枝记账或国家计算机信息系统；</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">（</span><span>5</span
      ><span style="font-family: 宋体"
        >）不得传输或发表任何违法犯罪的、骚扰性的、中伤他人的、辱骂性的、恐吓性的、伤害性的、庸俗的，淫秽的、不文明的等信息资料；</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">（</span><span>6</span
      ><span style="font-family: 宋体"
        >）不得传输或发表损害国家社会公共利益和涉及国家安全的信息资料或言论；</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">（</span><span>7</span
      ><span style="font-family: 宋体">）不得教唆他人从事本条所禁止的行为；</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">（</span><span>8</span
      ><span style="font-family: 宋体"
        >）不得利用在树枝记账注册的账户进行牟利性经营活动；</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">（</span><span>9</span
      ><span style="font-family: 宋体"
        >）不得发布任何侵犯他人著作权、商标权等知识产权或合法权利的内容。</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span>2</span
      ><span style="font-family: 宋体"
        >、若您未遵守以上规定，树枝记账有权作出独立判断并采取暂停或关闭账户、将违约或失信行为记录信用资料等措施，树枝记账有权在任何形式的媒体上公布。您须对自己在网上的言论和行为承担法律责任。</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">五、信息授权及隐私权保护</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span>1</span
      ><span style="font-family: 宋体"
        >、树枝记账在此提醒树枝记账用户：根据行业惯例，第三方平台通常自行或委托第三方信息服务平台（包括但不限于征信机构、中国支付清算协会互联网金融风险信息共享系统、中国人民银行金融信用信息基础数据库、公安部公民身份信息数据库、其他互联网数据树枝记账等）查询、采集、使用、识别、处理与树枝记账用户个人信息、个人征信信息及其他反应甲方信用状况的信息，包括但不限于个人基本信息、借贷交易信息、银行卡交易信息、手机账单信息、通讯记录信息、短信信息、电商交易信息、社保、公积金信息、公用事业信息、央行征信报告、能够联系到个人的实体地址、电子邮箱地址、微信号、反欺诈信息交叉验证等信息。用户经树枝记账引导，直接将相关信息提交、授权给第三方平台而非本树枝记账，树枝记账不承担因此带来的法律责任。</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span>2</span
      ><span style="font-family: 宋体"
        >、树枝记账用户同意并授权树枝记账可将本协议中约定部分业务服务外包给其他合作第三方，并为此目的将树枝记账用户个人信息提供给该合作第三方，且该业务第三方享有树枝记账在本合同项下的权利和授权，树枝记账用户违反本协议或树枝记账相关规则的，由此可能造成的树枝记账用户任何损失，树枝记账及合作的第三方不承担法律责任。</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span>3</span
      ><span style="font-family: 宋体"
        >、树枝记账用户知晓、理解并同意授权树枝记账采集、分析用户自愿提供输入的个人财务或账务信息以及获得用户及第三方平台授权采集的交易信息并采取合理的保密措施并授权树枝记账运用该数据进行商业分析。</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span>4</span
      ><span style="font-family: 宋体"
        >、根据法律规定及合理商业习惯，在树枝记账计划与其他公司合并或被其收购或进行其他资本市场活动（包括但不限于</span
      ><span>IPO</span
      ><span style="font-family: 宋体"
        >，债券发行）时，以及其他情形下树枝记账需要接受来自其他主体的尽职调查时，树枝记账会把树枝记账用户的信息提供给必要的主体，但树枝记账会通过和这些主体签署保密协议等方式要求其对树枝记账用户的个人信息采取合理的保密措施。</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">六、免责声明</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span>1</span
      ><span style="font-family: 宋体"
        >、
        除非另有书面协议约定，树枝记账在任何情况下，对用户使用本树枝记账服务而产生的任何形式的直接或间接损失均不承担法律责任，包括但不限于资金损失、利润损失、服务中断损失等。</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span>2</span
      ><span style="font-family: 宋体"
        >、
        任何树枝记账之外的第三方所提供的服务，其服务品质及内容由该第三方自行、独立负责。</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span>3</span
      ><span style="font-family: 宋体"
        >、
        因不可抗力或服务器死机、网络故障、数据库故障、软件升级或发生自然灾害、战争、恐怖袭击或者其他无法控制的事件等问题造成的服务中断和对用户个人数据及资料造成的损失，树枝记账不承担任何责任，亦不予赔偿。</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span>4</span
      ><span style="font-family: 宋体"
        >、
        因黑客、病毒或密码被盗、泄露等非树枝记账原因所造成损失概由用户本人自行承担。</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span>5</span
      ><span style="font-family: 宋体"
        >、
        当司法机关、政府部门或其他监管机构根据有关法律、法规、规章及其他政府规范性文件的规定和程序，要求树枝记账提供用户信息资料，树枝记账对据此作出的任何披露，概不承担责任。</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span>6</span
      ><span style="font-family: 宋体"
        >、由于中国法律政策的变动或监管机关的要求导致树枝记账开展的业务不被中国法律政策、监管机关允许或认可，由此造成的损失由用户自行承担，树枝记账不对此不承担责任。</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">七、风险提示</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span>1</span
      ><span style="font-family: 宋体"
        >、树枝记账用户了解并认可，树枝记账不能也没有义务为如下风险负责，但针对可能产生风险进行如下揭示：</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">（</span><span>1</span
      ><span style="font-family: 宋体"
        >）鉴于网络服务的特殊性，树枝记账不担保网络服务不会中断，对网络服务的及时性、安全性、准确性也都不作担保。</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">（</span><span>2</span
      ><span style="font-family: 宋体"
        >）因以下情况造成网络服务在合理时间内的中断，树枝记账无需为此承担任何责任：</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">（</span><span>a</span
      ><span style="font-family: 宋体"
        >）树枝记账需要定期或不定期地对树枝记账或相关的设备进行检修或者维护，树枝记账保留不经事先通知为维修保养、升级或其它目的暂停本服务任何部分的权利。</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">（</span><span>b</span
      ><span style="font-family: 宋体"
        >）因台风、地震、洪水、雷电、系统故障、设备故障、通讯故障、停电等突发事故，战争或恐怖袭击等不可抗力原因；</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">（</span><span>c</span
      ><span style="font-family: 宋体"
        >）用户的电脑软硬件和通信线路、供电线路出现故障的；</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">（</span><span>d</span
      ><span style="font-family: 宋体"
        >）因病毒、木马、恶意程序攻击、网络拥堵、系统不稳定、系统或设备故障、通讯故障、电力故障、银行原因、第三方服务瑕疵或政府行为等原因。
        尽管有前款约定，树枝记账将采取合理行动积极促使服务恢复正常。</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">（</span><span>3</span
      ><span style="font-family: 宋体"
        >）树枝记账或第三人可提供与其它互联网上之网站或资源之链接。由于树枝记账无法控制这些网站及资源，您了解并同意，此类网站或资源是否可供利用，树枝记账不予负责，存在或源于此类网站或资源之任何内容、广告、产品或其它资料，树枝记账亦不予保证或负责。因使用或依赖任何此类网站或资源发布的或经由此类网站或资源获得的任何内容、商品或服务所产生的任何损害或损失，树枝记账不承担任何责任。</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">（</span><span>4</span
      ><span style="font-family: 宋体"
        >）用户明确同意其使用树枝记账服务所存在的风险将完全由其自己承担。用户理解并接受下载或通过树枝记账取得的任何信息资料取决于用户自己，并由其承担系统受损、资料丢失以及其它任何风险。树枝记账对其任何服务、交易进程、广告信息等都不作担保。</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">（</span><span>5</span
      ><span style="font-family: 宋体"
        >）因树枝记账用户的过错导致的任何损失，该过错包括但不限于：决策失误、操作不当、遗忘或泄露密码、密码被他人破解、树枝记账用户使用的计算机系统被第三方侵入、树枝记账用户委托他人代理交易时他人恶意或不当操作而造成的损失。</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">八、知识产权条款</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span>1</span
      ><span style="font-family: 宋体"
        >、树枝记账上所有内容，包括但不限于著作、图片、档案、资讯、资料、树枝记账架构、树枝记账画面的安排、网页设计，均由树枝记账或其他权利人依法拥有其知识产权，包括但不限于商标权、专利权、著作权、商业秘密等。非经树枝记账或其他权利人书面同意，任何人不得以任何方式非法地全部或部分复制、转载、引用、链接、抓取、擅自使用、修改、公开传播、改变、散布、发行或公开发表树枝记账程序或内容，否则树枝记账或其他权利人有权追究其法律责任。</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span>2</span
      ><span style="font-family: 宋体"
        >、未经树枝记账及其他权利人明确书面同意，任何人不得以任何商业目的对树枝记账网站或其任何部分进行复制、复印、仿造、出售、转售、访问、或以其他方式加以利用。</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span>3</span
      ><span style="font-family: 宋体"
        >、尊重知识产权是树枝记账用户应尽的义务，如有违反，树枝记账用户应对树枝记账及其他权利人承担损害赔偿等法律责任。</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">九、责任限制及不承诺担保</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span>1</span><span style="font-family: 宋体">、除非另有明确的书面说明</span
      ><span>, </span
      ><span style="font-family: 宋体"
        >树枝记账及其所包含的或以其它方式通过网站或移动客户端提供给您的全部信息、内容、材料、产品（包括软件）和服务，均是在</span
      ><span>"</span><span style="font-family: 宋体">按现状</span><span>"</span
      ><span style="font-family: 宋体">和</span><span>"</span
      ><span style="font-family: 宋体">按现有</span><span>"</span
      ><span style="font-family: 宋体">的基础上提供的。除非另有明确的书面说明</span
      ><span>,</span
      ><span style="font-family: 宋体"
        >树枝记账不对树枝记账运营及其包含在树枝记账上的信息、内容、材料、产品（包括软件）或服务作任何形式的、明示或默示的声明或担保（根据中华人民共和国法律另有规定的以外）。</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span>2</span
      ><span style="font-family: 宋体"
        >、树枝记账不担保树枝记账所包含的或以其它方式通过树枝记账提供给树枝记账用户的全部信息、内容、材料、产品（包括软件）和服务、其服务器或从树枝记账发出的电子信件、信息没有病毒或其他有害成分。</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span>3</span
      ><span style="font-family: 宋体"
        >、如因不可抗力或其它树枝记账无法控制的原因使树枝记账系统崩溃或无法正常使用导致树枝记账服务无法完成或丢失有关的信息、记录等，树枝记账会合理地尽力协助处理善后事宜。</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span>4</span
      ><span style="font-family: 宋体"
        >、基于互联网的特殊性，无法保证树枝记账的服务不会中断，对于包括但不限于设备、系统存在缺陷，计算机发生故障、遭到病毒、黑客攻击或者发生地震、海啸等不可抗力而造成服务中断或因此给树枝记账用户造成的损失，由树枝记账用户自行承担。</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">十、条款的解释、法律适用及争端解决</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span>1</span
      ><span style="font-family: 宋体"
        >、本协议是由您与树枝记账运营者共同签订的，适用于您在树枝记账的全部活动，树枝记账及树枝记账运营者有最终的解释权。</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span>2</span
      ><span style="font-family: 宋体"
        >、本协议不涉及您与其他树枝记账用户之间以及因使用树枝记账服务而产生的法律关系及法律纠纷。但您在此同意将全面接受并履行与其他树枝记账用户在以及使用树枝记账服务签订的任何电子法律文本，并承诺按照该法律文本享有和（或）放弃相应的权利、承担和（或）豁免相应的义务。</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span>3</span
      ><span style="font-family: 宋体"
        >、如本协议中的任何条款无论因何种原因完全或部分无效或不具有执行力，则应认为该条款可与本协议相分割，并可被尽可能接近各方意图的、能够保留本协议要求的经济目的的、有效的新条款所取代，而且，在此情况下，本协议的其他条款仍然完全有效并具有约束力。</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span>4</span
      ><span style="font-family: 宋体"
        >、本协议及其修订的有效性、履行与本协议及其修订效力有关的所有事宜，将受中国大陆法律管辖，任何争议仅适用中国法律。</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span>5</span
      ><span style="font-family: 宋体"
        >、本协议签订地为中国北京市海淀区。因本协议所引起的树枝记账用户与树枝记账、树枝记账运营者的任何纠纷或争议，首先应友好协商解决，协商不成的，树枝记账用户在此完全同意将纠纷或争议提交签订地有管辖权的人民法院诉讼解决。</span
      >
    </p>
  </div>
</template>

<script>
export default {
  name: "privacy",
};
</script>

<style lang="less" scoped>
.privacy {
  padding: 15px;
  .right {
    text-align: right;
  }
  .title {
    font-size: 18px;
    color: #333;
    text-align: center;
    line-height: 36px;
    padding: 10px;
  }
  .content {
    text-indent: 25px;
  }
  .content em {
    font-style: normal;
    text-decoration: underline;
  }
  .content b {
    color: #333;
    text-decoration: underline;
  }
  .bold {
    font-weight: bold;
  }
  p {
    font-size: 14px;
    color: #333;
    line-height: 23px;
    margin-bottom: 7px;
    word-break: break-all;
  }
}
</style>
